import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { Layout } from "../components/Layout"

const seoData = {
  title: "Страница не найдена",
}

export default function NotFoundPage() {
  return (
    <Layout seoData={seoData} hasCustomFooter>
      <Box display="flex" justifyContent="center" padding={6}>
        <Typography variant="h3">Страница не найдена</Typography>
      </Box>
    </Layout>
  )
}
